<template>
  <section class="top-filter">
    <div class="mt12 mb12">
      <FilterLayout @doReset="resetForm"
                    @doSearch="doSearch"
                    ref="filterLayoutRef">
      <a-form
        ref="formRef"
        class="form rms-form"
        layout="vertical"
        :model="formState"
      >
        <a-form-item label="权限类型" name="permType" class="rms-form-item">
          <a-select
            v-model:value="formState.permType"
            placeholder="请选择"
            allowClear
          >
            <a-select-option value="menu">menu</a-select-option>
            <a-select-option value="btn">btn</a-select-option>
            <a-select-option value="router">router</a-select-option>
            <a-select-option value="path">path</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          label="所属菜单"
          name="permMenu"
          class="rms-form-item"
          v-show="formState.permType !== 'menu'"
        >
          <a-select
            v-model:value="formState.permMenu"
            placeholder="请选择"
            allowClear
          >
            <a-select-option v-for="item in menuList" :key="item" :value="item">
              {{ item }}
            </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item
          label="权限名称"
          name="permName"
          class="rms-form-item"
          v-if="isSearchByName"
        >
          <a-input
            v-model:value="formState.permName"
            placeholder="权限名称"
            enter-button
            allowClear
            autocomplete="off"
          />
        </a-form-item>
      </a-form>
      </FilterLayout>
      <div class="buttons-wrap" v-if="isHaveAdd">
        <a-button class="right" type="primary" @click="addClick" v-auth="['system:permission:add']">
          <PlusOutlined />
          新增
        </a-button>
    </div>
    </div>
  </section>
</template>
<script>
import { defineComponent, reactive, ref, onMounted, onActivated } from "vue";
import { useGlobalPropertyHook } from "@/hooks/common";
import {cloneDeep} from "lodash";
import { PlusOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  name: "TopFilter",
  components: { PlusOutlined },
  emits: ["search", "add-click", "resetTable"],
  props: {
    isHaveAdd: {
      type: Boolean,
      default: true,
    },
    isSearchByName: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();

    const formRef = ref();

    const resetForm = () => {
      formRef.value.resetFields();
      updateParams()
      ctx.emit("search", currentState);
    };
    let currentState  = {}
    const updateParams =() => {
      currentState = cloneDeep(formState);
    }
    const formState = reactive({
      permType: undefined,
      permMenu: undefined,
      permName: "",
    });
    const doSearch = () => {
      updateParams()
      ctx.emit("search", currentState);
    };
    const addClick = () => {
      ctx.emit("add-click");
    };

    const menuList = ref([]);
    const getMenuList = () => {
      const params = {
        pageIndex: 1,
        pageSize: 100,
        permType: "menu",
      };
      $api.getPermissionList(params).then((res) => {
        menuList.value = res.data.resource.map((item) => item.permName);
      });
    };

    onMounted(() => {
      getMenuList();
      doSearch();
    });

    onActivated(() => {
      ctx.emit("resetTable");
    });

    return {
      formState,
      menuList,
      formRef,
      doSearch,
      resetForm,
      addClick,
      updateParams,
    };
  },
});
</script>
<style scoped lang="less">
.top-filter {
  width: 100%;
  .search {
    display: flex;
    justify-content: space-between;
  }
}
</style>
