<template>
  <!--  <div>角色权限关系管理</div>-->
  <section class="app-container">
    <a-row class="bottom-content">
      <a-col :span="7" class="list-box">
        <a-table
          class="left-table"
          :columns="columns1"
          :data-source="rolePermList"
          :pagination="false"
          row-key="roleId"
          :scroll="{
            y: leftTableHeight,
            scrollToFirstRowOnChange: true,
          }"
          :row-selection="{
            selectedRowKeys: selectedRole,
            onChange: roleChange,
            type: 'radio',
          }"
        >
        </a-table>
        <div class="btn">
          <a-button type="primary" @click="confirm" class="confirm-btn" v-auth="['system:role:confirm']"
            >保存</a-button
          >
          <a-button type="primary" @click="cancel" class="cancel-btn" v-auth="['system:role:confirm']"
            >取消</a-button
          >
        </div>
      </a-col>
      <a-col :span="1"> </a-col>
      <a-col :span="16" class="matchor-box">
        <Filter class="filter" :is-have-add="false" @search="conditionChange" />
        <a-table
          :scroll="{
            y: tableHeight,
            scrollToFirstRowOnChange: true,
          }"
          class="right-table"
          :columns="columns2"
          :data-source="allPerms"
          :pagination="false"
          row-key="permId"
          :row-selection="{
            selectedRowKeys: selectedPerms,
            onChange: permsChange,
          }"
        >
        </a-table>
      </a-col>
    </a-row>
  </section>
</template>

<script>
import {
  defineComponent,
  ref,
  nextTick,
  onMounted,
  onUnmounted,
  reactive,
  toRefs,
  onActivated,
} from "vue";
import { message } from "ant-design-vue";
import { useTableHeight } from "@/utils/common";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import Filter from "@/views/home/ConfigManagement/Permission/components/Filter";

export default defineComponent({
  name: "maintainRole",
  components: { Filter },
  setup() {
    const { $api } = useGlobalPropertyHook();
    const columns1 = [
      {
        title: "角色Key",
        dataIndex: "roleKey",
        ellipsis: true,
      },
      {
        title: "角色名称",
        dataIndex: "roleName",
        ellipsis: true,
      },
      {
        title: "角色描述",
        dataIndex: "remark",
        ellipsis: true,
      },
    ];
    const columns2 = [
      {
        title: "权限名称",
        dataIndex: "permName",
        ellipsis: true,
      },
      {
        title: "权限类型",
        dataIndex: "permType",
        ellipsis: true,
      },
      {
        title: "Path",
        dataIndex: "path",
        ellipsis: true,
      },
    ];
    const rolePermList = ref([]);
    const allPerms = ref([]);
    const checkedPerms = ref([]);
    const state1 = reactive({
      selectedRole: [],
      loading: false,
    });
    const state2 = reactive({
      selectedPerms: [],
      loading: false,
    });

    const tableHeight = ref(0);
    const leftTableHeight = ref(0)

    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight(false, ".right-table");
        leftTableHeight.value = useTableHeight(false, ".left-table", "", "",100);
      });
    };

    window.addEventListener("resize", getSize);

    onMounted(() => {
      getSize();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });

    const roleChange = (selectedRole) => {
      state1.selectedRole = selectedRole;
      findPermsByRoleId(selectedRole);
    };

    const permsChange = (selectedPerms) => {
      state2.selectedPerms = selectedPerms;
    };

    const getRolePermListData = () => {
      $api.getRoleInfo().then((res) => {
        rolePermList.value = res.data;
      });
    };

    let searchParams = {};
    const conditionChange = (conditon) => {
      searchParams = conditon;
      getPermsList();
    };

    const getPermsList = () => {
      const params = {
        menu: searchParams.permMenu,
        name: searchParams.permName,
        permType: searchParams.permType,
      };
      $api.getPermsList(params).then((res) => {
        allPerms.value = res.data;
      });
    };

    const findPermsByRoleId = (selectedRole) => {
      const data = {
        roleId: undefined,
      };
      data.roleId = selectedRole[0];
      $api.getPermsByRoleId(data).then((res) => {
        if (res.data != undefined) {
          state2.selectedPerms = res.data.map((item) => item.permId);
        } else {
          state2.selectedPerms = [];
        }
      });
    };

    const confirm = () => {
      const data = {
        roleId: state1.selectedRole[0],
        permList: state2.selectedPerms,
      };
      $api
        .updateStaffRolePerms(data)
        .then(() => {
          findPermsByRoleId(state1.selectedRole);
          message.success("保存成功！");
        })
        .catch((error) => {
          console.log(error);
          message.error("保存失败！");
        });
    };

    const cancel = () => {
      findPermsByRoleId(state1.selectedRole);
    };

    onMounted(() => {
      state1.selectedRole = ["1"];
    });

    onActivated(() => {
      getRolePermListData();
      getPermsList();
      findPermsByRoleId(state1.selectedRole);
    });

    return {
      columns1,
      columns2,
      rolePermList,
      allPerms,
      checkedPerms,
      ...toRefs(state1),
      ...toRefs(state2),
      tableHeight,
      getRolePermListData,
      getPermsList,
      roleChange,
      permsChange,
      confirm,
      cancel,
      conditionChange,
      leftTableHeight,
    };
  },
});
</script>

<style scoped lang="less">
.app-container {
  height: 100%;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;

  .list-box {
    overflow-y: scroll;
  }
}
::v-deep .ant-table-body {
  &::-webkit-scrollbar {
    width: 13px;
    height: 13px;
  }

  &::-webkit-scrollbar-thumb {
    width: 13px;
    height: 13px;
    background: #d0e8ff;
    border: 1px solid #d0e8ff;
    border-radius: 5px;
  }
}

//::v-deep .ant-btn {
//  height: 35px;
//  width: 90px;
//  font-size: 18px;
//}
.page {
  height: 100%;
}
.btn {
  margin: 20px;
  .confirm-btn {
    margin-right: 50px;
  }
}
.filter {
  height: auto;
  margin-bottom: 20px;
}
</style>
